import React from 'react';
import { 
    Box,
    Grid,
    Container,
    Typography,
} from '@mui/material';

import {
    Page,
} from '../../../components';

interface Props {}

const HomeScreen: React.FC<Props> = () => {

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">
                        Visão geral do dia
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    
                </Grid>
            </Container>
        </Page>
    )
};

export default HomeScreen;