import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { EventFilters } from './../../../typings/event.types';
import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { EVENT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { IndexEventsResponse } from '../../../typings';
import dayjs from 'dayjs';

const fetchEvents = (
    page: number,
    perPage: number,
    filters?: EventFilters,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: EVENT_ACTIONS.FETCH_EVENTS,
            });

            let dateFormat = {};

            if (filters?.to && filters?.from) {
                dateFormat = {
                    from: dayjs(filters.from).locale('pt-BR').toISOString(),
                    to: dayjs(filters?.to).locale('pt-BR').toISOString(),
                }
            }

            const response = await api.get<
                IndexEventsResponse, 
                AxiosResponse<IndexEventsResponse>
            >
            (`${ROUTES.EVENTS.INDEX}`,
                {   
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {
                        page,
                        perPage,
                        type: filters?.type,
                        funnel: filters?.funnel,
                        ...dateFormat,
                    },
                }
            );

            if (response.data?.data && response?.data?.errors?.length === 0) {
                const { data: events, totalItems } = response?.data?.data;

                dispatch({
                    type: EVENT_ACTIONS.FETCH_EVENTS_SUCCESS,
                    payload: {
                        events,
                        totalItems,
                    },
                });
                
                return callback && callback(false, 'Eventos carregados com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: EVENT_ACTIONS.FETCH_EVENTS_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    IndexEventsResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: EVENT_ACTIONS.FETCH_EVENTS_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: EVENT_ACTIONS.FETCH_EVENTS_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default fetchEvents;