import { initialState } from '../../reducers/funnels';

export const FUNNELS_ACTIONS = {
    // FETCH FUNNELS
    FETCH_FUNNELS: '[FUNNELS] FETCH_FUNNELS',
    FETCH_FUNNELS_SUCCESS: '[FUNNELS] FETCH_FUNNELS_SUCCESS',
    FETCH_FUNNELS_FAILED: '[FUNNELS] FETCH_FUNNELS_FAILED',

    // DESTROY FUNNEL
    DESTROY_FUNNEL: '[FUNNEL] DESTROY_FUNNEL',
    DESTROY_FUNNEL_SUCCESS: '[FUNNEL] DESTROY_FUNNEL_SUCCESS',
    DESTROY_FUNNEL_FAILED: '[FUNNEL] DESTROY_FUNNEL_FAILED',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[FUNNEL] SET_VALUE',
    CLEAR_VALUES: '[FUNNEL] CLEAR_VALUES',

    // TOGGLE MODAL
    TOGGLE_MODAL: '[FUNNEL] TOGGLE_MODAL',

};

export type AvailableFunnelModals = keyof typeof initialState.modal;

export const toggleModal = (key: AvailableFunnelModals, v: boolean) => {
    return ({
        type: FUNNELS_ACTIONS.TOGGLE_MODAL,
        payload: {
            [key]: v,
        },
    });
}

export const setValue = (payload: any) => ({
    type: FUNNELS_ACTIONS.SET_VALUE,
    payload,
});

export const clearValues = () => ({ type: FUNNELS_ACTIONS.CLEAR_VALUES });
export { default as fetchFunnels } from './fetchFunnels.action';
