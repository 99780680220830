import { Link as RouterLink } from 'react-router-dom';

import { Box, Card, Link, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const BusinessImage = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
});

interface Props {
    socialName: string,
    coverImage: string,
    plan: string,
    onPress(): void,
}

const MyBusinessCard: React.FC<Props> = ({
    socialName,
    coverImage,
    plan,
    onPress,
}) => {
    return (
        <Grid key={socialName} item xs={12} sm={6} md={3}>
            <Card>
                <Box sx={{ pt: '100%', position: 'relative' }}>
                    <BusinessImage src={coverImage} />
                </Box>

                <Stack spacing={2} sx={{ p: 4 }}>
                    <Link onClick={onPress} to="/app" color="inherit" underline="hover" component={RouterLink}>
                        <Typography variant="subtitle2" noWrap>
                            {socialName}
                        </Typography>
                    </Link>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            <Typography
                                component="span"
                                variant="body1"
                                sx={{
                                    color: 'text.disabled',
                                }}
                            >
                                {plan}
                            </Typography>
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </Grid>
    )
};

export default MyBusinessCard;