import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack } from '@mui/material';

import { 
    MHidden,
    Scrollbar,
    NavSection,
} from '../../components';
import sidebarConfig from './sidebar-config';
import { ReduxReducers } from '../../store/reducers';
import { AuthState } from '../../store/reducers/auth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex
        }),
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadius,
}));

interface Props {
    isOpenSidebar: boolean,
    onCloseSidebar(): void,
};

const DashboardSidebar: React.FC<Props> = ({ isOpenSidebar, onCloseSidebar }) => {
    const { isCollapse, collapseClick, collapseHover } =
    useCollapseDrawer();
    const { user, organization, permissions } = useSelector<ReduxReducers, AuthState>(s => s.auth);

    const { pathname } = useLocation();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center'
                    })
                }}
            >
    
            {isCollapse ? (
                <Avatar src={user?.name} alt={user?.name} />
            ) : (
                <Link underline="none" component={RouterLink} to={'/bots'}>
                    <AccountStyle>
                        <Avatar src={user?.name} alt={user?.name} />
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {user?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {organization?.name} ({organization?.businessCode})
                            </Typography>
                        </Box>
                    </AccountStyle>
                </Link>
            )}
            </Stack>
    
            <NavSection navConfig={sidebarConfig(Number(permissions)) as any} isShow={!isCollapse} />
    
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
                },
                ...(collapseClick && {
                    position: 'absolute'
                })
            }}
        >
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
    
            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)',
                                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                            })
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}

export default DashboardSidebar;