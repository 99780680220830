import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { FUNNELS_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { DeleteFunnelResponse, IndexFunnelsResponse } from '../../../typings';

const destroyFunnel = (
    id: number,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;
            const { funnels } = getState().funnels;

            dispatch({
                type: FUNNELS_ACTIONS.DESTROY_FUNNEL,
            });

            const response = await api.delete<
                DeleteFunnelResponse, 
                AxiosResponse<DeleteFunnelResponse>
            >
            (`${ROUTES.FUNNELS.INDEX}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.data && response.status === 200) {
                dispatch({
                    type: FUNNELS_ACTIONS.DESTROY_FUNNEL_SUCCESS,
                    payload: {
                        funnels: funnels?.filter((f) => f?.id !== id),
                    },
                });
                
                return callback && callback(false, 'Funil excluído com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: FUNNELS_ACTIONS.DESTROY_FUNNEL_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    IndexFunnelsResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: FUNNELS_ACTIONS.DESTROY_FUNNEL_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: FUNNELS_ACTIONS.DESTROY_FUNNEL_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default destroyFunnel;