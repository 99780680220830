import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

import Loading from '../../loading';
import { Event, User, } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import { PaginatorFactory } from '../../../factory';
import NoData from '../../no-data';
import { UserState } from '../../../store/reducers/users';
import { destroyUser, fetchUsers, setValue, toggleModal } from '../../../store/actions/users';
import { USER_COLUMNS } from '../../../builders/user.builders';
import UserForm from '../../forms/user.form';
import { MoreOptionsList } from '../more-options';
import AreYouSureModal from '../../are-you-sure-modal';

export const UserList: React.FC = () => {
    const dispatch = useDispatch();
    const { users, loading, modal } = useSelector<ReduxReducers, UserState>(s => s.users);
    const [user, setUser] = useState<User | undefined>(undefined);

    const [pagination, setPagination] = useState<PaginatorFactory<Omit<Event, 'id'>>>({
        page: 0,
        perPage: 50,
    });

    useEffect(() => {
        dispatch(
            fetchUsers(
                pagination?.page + 1,
                pagination?.perPage,
            )
        );
    }, [pagination]);

    return (
        <>
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {USER_COLUMNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                                <TableCell>
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <When condition={!loading.index}>
                            <TableBody>
                                {users?.map((user) => (
                                    <TableRow key={user?.id}>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {user?.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {user?.name || '----'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {user?.email || '----'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {user?.phone || '----'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(user?.created_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <MoreOptionsList
                                                options={[
                                                    {
                                                        icon: <EditIcon fontSize="small" />,
                                                        label: 'Editar',
                                                        onClick: () => {
                                                            dispatch(setValue({ user }));
                                                            dispatch(toggleModal('edit', true));
                                                        },
                                                    },
                                                    {
                                                        icon: <DeleteIcon fontSize="small" />,
                                                        label: 'Excluir',
                                                        onClick: () => {
                                                            setUser(user);
                                                            dispatch(toggleModal('destroy', true));
                                                        },
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>

                    <When condition={loading?.index !== true && users?.length < 1}>
                        <NoData 
                            buttonText=''
                            onClick={() => {}}
                            title={'Nenhum usuário foi encontrado.'}
                            description={'Aparentemente, não há nenhum evento que contemple o filtro indicado.'}
                        />
                    </When>

                    <When condition={loading?.index}>
                        <Loading />
                    </When>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={1}
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                />
            </Card>

            <UserForm />
            
            <AreYouSureModal
                title="Tem certeza?"
                description="Tem certeza que deseja desativar esse usuário? Ele será excluído permanentemente."
                loading={loading?.destroy}
                visible={modal?.destroy}
                onHide={() => dispatch(toggleModal('destroy', false))}
                onConfirm={() => {
                    dispatch(
                        destroyUser(
                            user?.id!,
                            (err, msg) => {
                                dispatch(toggleModal('destroy', false));

                                toast(
                                    msg,
                                    {
                                        autoClose: 1500,
                                        type: err ? 'error' : 'success',
                                    }
                                );
                            },
                        ),
                    );
                }}
            />
        </>
    )
}