import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { AUTH_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { AuthLoginResponse } from '../../../typings';

const login = (
    email: string,
    password: string,
    callback: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch({
                type: AUTH_ACTIONS.LOGIN,
            });

            const response = await api.post<AuthLoginResponse, AxiosResponse<AuthLoginResponse>>(ROUTES.AUTH.LOGIN, { email, password });

            if (response.data?.data?.token && response?.data?.errors?.length === 0) {
                const { organization, token, userData: user, permissions } = response?.data?.data;

                dispatch({
                    type: AUTH_ACTIONS.LOGIN_SUCCESS,
                    payload: {
                        token,
                        user,
                        organization,
                        permissions
                    },
                });
                
                return callback && callback(false, 'Conectado com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: AUTH_ACTIONS.LOGIN_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])
        } catch (error: any) {

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<AuthLoginResponse>).response?.data?.errors[0];
                dispatch({
                    type: AUTH_ACTIONS.LOGIN_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: AUTH_ACTIONS.LOGIN_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default login;