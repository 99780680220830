import { AnyAction } from 'redux';

import { User } from '../../../typings';
import { USERS_ACTIONS } from '../../actions/users';

export interface UserState {
    users: User[] | [],
    user: User | null,
    loading: {
        index: boolean,
        create: boolean,
        edit: boolean,
        destroy: boolean,
    },
    modal: {
        create: boolean,
        edit: boolean,
        destroy: boolean,
    },
};

export const initialState: UserState = {
    users: [],
    user: null,
    loading: {
        index: false,
        create: false,
        edit: false,
        destroy: false,
    },
    modal: {
        create: false,
        edit: false,
        destroy: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
         // DESTROY
         case USERS_ACTIONS.DESTROY_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: true,
                },
            }

        case USERS_ACTIONS.DESTROY_USER_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }
            
        case USERS_ACTIONS.DESTROY_USER_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }

        // CREATE USER
        case USERS_ACTIONS.CREATE_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            }
        
        case USERS_ACTIONS.CREATE_USER_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            }

        case USERS_ACTIONS.CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            }

        // UPDATE USER
        case USERS_ACTIONS.UPDATE_USER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            }
        
        case USERS_ACTIONS.UPDATE_USER_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            }

        case USERS_ACTIONS.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            }

        // FETCH USERS
        case USERS_ACTIONS.FETCH_USERS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                },
            }

        case USERS_ACTIONS.FETCH_USERS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        case USERS_ACTIONS.FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: payload,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        // MODAL HANDLER
        case USERS_ACTIONS.TOGGLE_MODAL:
            return {
                ...state,
                modal: {
                    ...payload,
                }
            }
        // DEFAULT ACTIONS
        case USERS_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case USERS_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        
    }
}

export default reducer;