import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import _ from 'lodash';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WatchIcon from "@mui/icons-material/Timelapse";
import { When } from 'react-if';
import dayjs from 'dayjs';
import { USER_FORM_INPUTS } from '../../builders/user.builders';
import FormBuilder from '../form-builder';
import { ReduxReducers } from '../../store/reducers';
import { UserState } from '../../store/reducers/users';
import { toggleModal, updateUser } from '../../store/actions/users';
import { User } from '../../typings';
import { Icon } from '@iconify/react';
import createUser from '../../store/actions/users/createUser.action';
import { localPrivs, getPrivByCode, checkPriv } from '../../utils/privs'

interface Props {}

const UserForm: React.FC<Props> = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);
    const { user, loading, modal } = useSelector<ReduxReducers, UserState>(s => s.users);
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isFromEdit = user && modal?.edit;
    const [privs, setPrivs] = useState(Number(user?.permissions));
    const [privsSelected, setPrivsSelected] = useState({} as any);

    const afterSubmit = (err?: boolean, msg?: string) => {
        if (err) {
            toast(
                msg,
                {
                    autoClose: 1500,
                    type: 'error',
                }
            );
            return
        }

        formik.resetForm();

        dispatch(toggleModal('create', false));
        dispatch(toggleModal('edit', false));

        toast(
            msg,
            {
                autoClose: 1500,
                type: 'success',
            },
        );
    }

    const VALIDATION_SCHEMA = Yup.object().shape({
        name: Yup
            .string()
            .min(2, 'Nome muito pequeno')
            .max(50, 'Nome muito grande')
            .required('Informe o nome do usuário'),
        email: Yup
            .string()
            .optional()
            .nullable(),
        cpf: Yup
            .string()
            .optional()
            .nullable(),
        phone: Yup
            .string()
            .optional()
            .nullable(),
        birthday: Yup
            .string()
            .optional()
            .nullable(),
        password: Yup
            .string()
            .oneOf([Yup.ref('repeatPassword'), null], 'As senhas não coincidem')
            .required('Insira a sua senha'),
        repeatPassword: Yup
            .string()
            .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
            .required('Repita a sua senha'),
    });

    const formik = useFormik<User & { password?: string, repeatPassword?: string }>({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            cpf: '',
            birthday: null,
            gender: '',
            password: '',
            repeatPassword: '',
        } as User & { password?: string, repeatPassword?: string },
        validationSchema: isFromEdit ? null : VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            if (!isFromEdit) {
                dispatch(createUser(values, (err, msg) => {
                    afterSubmit(err, msg);
                }));
            } 
            else {
                dispatch(
                    updateUser(
                        user?.id,
                        {
                            ...values,
                            permissions: String(privs),
                        },
                        (err, msg) => {
                            afterSubmit(err, msg);
                        },
                    ),
                );
            }
        }
    });

    const { 
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        getFieldProps,
    } = formik;

    const onClose = () => {
        formik.resetForm();
        dispatch(toggleModal('create', false));
        dispatch(toggleModal('edit', false));
    }

    useEffect(() => {
        if (isFromEdit) {
            formik.setValues({
                ...user,
            });
        }
    }, [modal?.edit]);

    const handleChangeCheckbox = (event: any) => {
        const privName: any = getPrivByCode(localPrivs, Number(event.target.value))
        setPrivsSelected({ ...privsSelected, [privName]: !privsSelected[privName] })
    };

    useEffect(() => {
        let sumPrivs = 0
        Object.keys(privsSelected)?.map((p: any) => {
            if (privsSelected[p]) sumPrivs += localPrivs[p]
        })

        setPrivs(sumPrivs)
    }, [privsSelected])

    useEffect(() => {
        let privs = {}
        Object.keys(localPrivs).map((priv: string) => {
            const r = !isFromEdit ? false : checkPriv(Number(user?.permissions), Number(localPrivs[priv]))
            privs = { ...privs, [priv]: r }
        })

        setPrivsSelected(privs)
    }, [user, isFromEdit])

    return (
        <Dialog 
            maxWidth='sm'
            open={modal?.create || modal?.edit} 
            scroll={'paper'}
            fullWidth
            fullScreen={smallScreen}
            onClose={onClose}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography sx={{ ml: 3, mb: 2, mt: 2}} fontSize={18} fontWeight={400} color={'text.default'}>
                        {isFromEdit ? `${user?.name}` : 'Novo usuário'}
                    </Typography>
                    <Tooltip title="Fechar formulário">
                        <IconButton
                            onClick={onClose}
                            component="span"
                            sx={{ mr: 2, height: 30, width: 30, alignSelf: 'center' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Stack spacing={3}>
                            <Grid container spacing={1}>
                                <FormBuilder 
                                    errors={errors}
                                    touched={touched}
                                    inputs={isFromEdit ?
                                        [...USER_FORM_INPUTS?.filter(i => i.id === 'name')] 
                                        :
                                        [...USER_FORM_INPUTS?.filter(i => i.id === 'name' || i.id === 'email')] 
                                    }
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    getFieldProps={getFieldProps}
                                />

                                <When condition={!isFromEdit}>
                                    <Grid item md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? 'text' : 'password'}
                                            label="Senha"
                                            {...getFieldProps('password')}
                                            InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                            }}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                    </Grid>
                                    
                                    <Grid item md={6}>
                                        <TextField
                                            fullWidth
                                            autoComplete="current-password"
                                            required
                                            type={showRepeatPass ? 'text' : 'password'}
                                            label="Repetir Senha"
                                            {...getFieldProps('repeatPassword')}
                                            InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" onClick={() => setShowRepeatPass((prev) => !prev)}>
                                                        <Icon icon={showRepeatPass ? eyeFill : eyeOffFill} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                            }}
                                            error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                                            helperText={touched.repeatPassword && errors.repeatPassword}
                                        />
                                    </Grid>
                                </When>
                                
                                <FormBuilder 
                                    errors={errors}
                                    touched={touched}
                                    inputs={[...USER_FORM_INPUTS?.filter(i => i.id !== 'name' && i.id !== 'email')]}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    getFieldProps={getFieldProps}
                                />
                            </Grid>

                            <Grid item md={12}>
                                Permissões:<br/>
                                {Object.keys(localPrivs).map((priv: string) => {
                                    return (<FormControlLabel control={<Checkbox onChange={handleChangeCheckbox} checked={privsSelected[priv]} value={localPrivs[priv]} />} label={priv} />);
                                })}
                            </Grid>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ borderTop: 1, borderColor: 'divider', justifyContent: 'space-between', p: 2 }}>
                        <Stack sx={{ flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}>
                            <When condition={isFromEdit}>
                                <WatchIcon sx={{ mr: 1 }} />
                                <div>
                                    <Typography variant="body2" sx={{ flexDirection: 'row', display: 'flex' }}>
                                        Criado em:
                                        <Typography variant="body2">
                                            {dayjs(user?.created_at).format('DD/MM/YYYY HH:MM')}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="body2" sx={{ flexDirection: 'row', display: 'flex' }}>
                                        Última atualização: {' '}
                                        <Typography variant="body2">
                                            {dayjs(user?.updated_at).format('DD/MM/YYYY HH:MM')}
                                        </Typography>
                                    </Typography>
                                </div>
                            </When>
                        </Stack>
                        <LoadingButton 
                            variant="contained"
                            startIcon={<CheckIcon />}
                            type="submit"
                            loading={loading?.create || loading?.edit}
                        >
                            Salvar alterações
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

export default UserForm;