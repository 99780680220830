import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { BOT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { UpdateBotResponse } from '../../../typings';
import { ReduxReducers } from '../../reducers';

const updateBot = (
    id: number,
    name: string,
    hourlyLimitSup: number,
    hourlyLimitInf: number,
    secondsRetry: number,
    setup: string,
    typeBot: string,
    callback: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;
            const { bots } = getState().bots;

            dispatch({
                type: BOT_ACTIONS.UPDATE_BOT,
            });

            const response = await api.put<UpdateBotResponse, AxiosResponse<UpdateBotResponse>>(
               `${ROUTES.BOT.UPDATE}/${id}`, 
                { name, hourlyLimitSup, hourlyLimitInf, secondsRetry, setup, typeBot },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

            if (response.data?.data && response?.data?.errors?.length === 0) {
                dispatch({
                    type: BOT_ACTIONS.UPDATE_BOT_SUCCESS,
                    payload: {
                        bots: bots?.map(b => b?.id === id ? ({ ...response?.data?.data }) : ({ ...b })),
                    }
                });
                
                return callback && callback(false, 'Bot alterado com sucesso!');
            } 

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: BOT_ACTIONS.UPDATE_BOT_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])
        } catch (error: any) {

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<UpdateBotResponse>).response?.data?.errors[0];
                dispatch({
                    type: BOT_ACTIONS.UPDATE_BOT_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: BOT_ACTIONS.UPDATE_BOT_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default updateBot;