function makePrivs(arr: any) {
  const obj: any = {};
  const formula = (i: any) => Math.pow(2, i);
  let pointer = 0;
  for (const item of arr) {
    obj[item] = formula(pointer++);
  }
  return obj;
}

const localPrivs: any = makePrivs([
  'getInstances',
  'getEvents',
  'createBots',
  'updateBots',
  'getBots',
  'deleteBots',
  'connectBots',
  'statusBots',
  'logoutBots',
  'createFunnels',
  'updateFunnels',
  'getFunnels',
  'deleteFunnels',
  'getLeads',
  'deleteLeads',
  'createLeadTracker',
  'getLeadTracker',
  'deleteLeadTracker',
  'forgotPassword',
  'recoverPassword',
  'createUsers',
  'updateUsers',
  'getUsers',
  'deleteUsers',
  'viewReport',
  'freeInstances',
  'importLeads',
  'setMasterLink',
  'viewAllReports',
  'deleteEvents'
]);

const globalPrivs = makePrivs([

]);

function getPrivByCode(arr: any, flag: any) {
  const arrKeys = Object.keys(arr);
  for (const item of arrKeys) {
    if (flag === arr[item]) return item;
  }
}

/**
 * Bitwise check if permission flag is present
 * @param {*} pool sum of user's privileges
 * @param {*} flag specified privilege code to check for
*/
const checkPriv = (pool: any, flag: any) => (pool === -1) || ((flag & pool) === flag);

export { localPrivs, globalPrivs, getPrivByCode, checkPriv };
export default checkPriv;
