import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';

import FormBuilder from '../../form-builder';
import { EventFilters, EventsEnum } from '../../../typings';
import { EventState } from '../../../store/reducers/events';
import { ReduxReducers } from '../../../store/reducers';
import dayjs from 'dayjs';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FunnelState } from '../../../store/reducers/funnels';

interface Props {
    onFilter(filters: EventFilters): Promise<void>,
}

export const EventListFilters: React.FC<Props> = ({ onFilter }) => {
    const currentDate = new Date();
    const [open, setOpen] = useState(true);
    const { loading } = useSelector<ReduxReducers, EventState>((state) => state.events);
    const { funnels } = useSelector<ReduxReducers, FunnelState>(state => state.funnels);

    const initial =  {
        from: dayjs(new Date()).subtract(1, 'day').toISOString().split('T')[0]+'T03:00:00Z',
        to: dayjs(new Date(currentDate.getTime() + 86400000)).toISOString().split('T')[0]+'T02:59:59Z',
        type: '',
        funnel: '',
    } as EventFilters;

    const formik = useFormik<EventFilters>({
        initialValues: { ...initial },
        onSubmit: async (filters) => {
           await onFilter(filters);
        }
    });

    const { 
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        getFieldProps,
    } = formik;

    return (
        <Box sx={{ p: 3 }}>
            <Accordion
                expanded={open}
                onChange={(e) => setOpen(o => !o)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon sx={{ mr: 1.5, ml: -1 }} />
                    <Typography fontSize={18} fontWeight={500}>
                        Filtros disponíveis
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={12}>
                            <Grid container spacing={1}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item md={3} lg={3} xs={3} sm={3}>
                                        <DateTimePicker
                                            label="Data inicial:"
                                            ampm={false}
                                            value={values?.from}
                                            onChange={(newValue: any) => {
                                                formik.setFieldValue('from', newValue.toISOString());
                                            }}
                                            inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={3} lg={3} xs={3} sm={3}>
                                        <DateTimePicker
                                            label="Data final:"
                                            ampm={false}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                            value={values?.to}
                                            onChange={(newValue: any) => {
                                                formik.setFieldValue('to', newValue.toISOString());
                                            }}
                                            inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormBuilder
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    inputs={[
                                        {
                                            label: 'Evento',
                                            id: 'type',
                                            type: 'select',
                                            required: false,
                                            placeholder: 'Selecione o evento',
                                            grid: {
                                                xs: 3,
                                                sm: 3,
                                                md: 3,
                                            },
                                            options: Object.values(EventsEnum).map((v) => ({
                                                label: v,
                                                value: v,
                                            }))
                                        },
                                        {
                                            label: 'Funil',
                                            id: 'funnel',
                                            type: 'select',
                                            required: false,
                                            placeholder: 'Selecione o funil',
                                            grid: {
                                                xs: 3,
                                                sm: 3,
                                                md: 3,
                                            },
                                            options: funnels.map((f) => ({
                                                label: f?.name,
                                                value: String(f?.id),
                                            }))
                                        },
                                    ]}
                                    setFieldValue={setFieldValue}
                                    getFieldProps={getFieldProps}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid display={'flex'} flexDirection={'row-reverse'} mt={2}>
                        <LoadingButton
                            variant='contained'
                            color={'primary'}
                            loading={loading?.index}
                            onClick={async () => {
                                await onFilter(values);
                            }}
                        >
                            Pesquisar
                        </LoadingButton>
                        <Button
                            disabled={loading?.index}
                            variant='text'
                            sx={{ mr: 1 }}
                            color={'primary'}
                            onClick={async () => {
                                formik.setValues({
                                    ...initial
                                });
                                await onFilter(initial as EventFilters);
                            }}
                        >
                            Limpar campos
                        </Button>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}