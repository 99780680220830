import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import Loading from '../../loading';
import { Event, EventFilters } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import { PaginatorFactory } from '../../../factory';
import { FUNNEL_COLUMUNS } from '../../../builders/funnel.builders';
import { EventState } from '../../../store/reducers/events';
import { fetchEvents } from '../../../store/actions/events';
import { EVENT_COLUMNS } from '../../../builders/event.builders';
import { EventListFilters } from './event-list-filters';
import { fetchFunnels } from '../../../store/actions/funnels';
import NoData from '../../no-data';

export const EventList: React.FC = () => {
    const currentDate = new Date();
    const dispatch = useDispatch();
    const { events, loading, totalItems } = useSelector<ReduxReducers, EventState>(s => s.events);

    const [pagination, setPagination] = useState<PaginatorFactory<Omit<Event, 'id'>>>({
        page: 0,
        perPage: 50,
    });

    const onFilter = async (filters: EventFilters) => {
        dispatch(
            fetchEvents(
                pagination?.page + 1,
                pagination?.perPage,
                filters,
            )
        );
    }

    useEffect(() => {
        dispatch(
            fetchEvents(
                pagination?.page + 1,
                pagination?.perPage,
                {
                    from: dayjs(currentDate).subtract(1, 'day').toISOString().split('T')[0]+'T03:00:00Z',
                    to: dayjs(new Date(currentDate.getTime() + 86400000)).toISOString().split('T')[0]+'T02:59:59Z',
                }
            )
        );
        dispatch(fetchFunnels(1, 150));
    }, [pagination]);

    return (
        <>
            <Card>
                <EventListFilters onFilter={onFilter} />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {EVENT_COLUMNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <When condition={!loading.index}>
                            <TableBody>
                                {events?.map((event) => (
                                    <TableRow key={event?.uuid}>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.uuid}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.leads?.email}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.default' }}>
                                                {event?.leads?.phone}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.organizations?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {event?.funnels?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(event?.created_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>

                    <When condition={loading?.index !== true && events?.length < 1}>
                        <NoData 
                            buttonText=''
                            onClick={() => {}}
                            title={'Nenhum evento foi encontrado.'}
                            description={'Aparentemente, não há nenhum evento que contemple o filtro indicado.'}
                        />
                    </When>

                    <When condition={loading?.index}>
                        <Loading />
                    </When>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                />
            </Card>
        </>
    )
}