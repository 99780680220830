import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

import DashboardNavbar from './navbar';
import DashboardSidebar from './sidebar';
import { BotForm, ConnectBot } from '../../components';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100vh',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    maxHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
}));

export default function DashboardLayout() {
    const { collapseClick } = useCollapseDrawer();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    return (
        <>
            <RootStyle>
                <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                <MainStyle
                    sx={{
                        transition: theme.transitions.create('margin', {
                            duration: theme.transitions.duration.complex
                        }),
                        ...(collapseClick && {
                            ml: '102px'
                        })
                    }}
                >
                    <Outlet />
                </MainStyle>
            </RootStyle>
            
            {/* Global modal forms (put on there for access anywhere) */}
            <BotForm />
            <ConnectBot />
        </>
    );
}