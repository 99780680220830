import { combineReducers } from "redux";

import auth, {
    AuthState,
} from './auth';

import bots, { 
    BotState
} from './bots';

import users, { 
    UserState,
} from './users';

import events, {
    EventState,
} from './events';

import reports, { 
    ReportState,
} from './reports';

import funnels, { 
    FunnelState,
} from './funnels';

import instances, {
    InstanceState,
} from './instances';

const rootReducer = combineReducers({
    auth,
    bots,
    users,
    reports,
    funnels,
    events,
    instances,
});

export type ReduxReducers = {
    auth: AuthState,
    bots: BotState,
    users: UserState,
    reports: ReportState,
    funnels: FunnelState,
    events: EventState,
    instances: InstanceState,
};

export default rootReducer;