import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { BOT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { DeleteBotResponse } from '../../../typings';
import { ReduxReducers } from '../../reducers';


const deleteBot = (
  botId: number,
  callback: (err: any, message?: string) => void,
) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
    try {
      const { token } = getState().auth;
      const { bots } = getState().bots;

      dispatch({
        type: BOT_ACTIONS.DELETE_BOT,
      });
      
      const response = await api.delete<DeleteBotResponse, AxiosResponse<DeleteBotResponse>>(
        `${ROUTES.BOT.DELETE}/${botId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: BOT_ACTIONS.DELETE_BOT_SUCCESS,
          payload: {
            bots: bots?.map(b => {
              if (b.id !== botId) {
                return { ... b };
              }

              return {
                ... b,
                deleted_at: new Date(),
              };
            }),
          },
        });
        
        return callback && callback(false, 'Bot deletado com sucesso!');
      }

      const ERROR_CODE = response.data?.errors[0];
      dispatch({
        type: BOT_ACTIONS.DELETE_BOT_FAILED,
      });

      return callback && callback(true, ERRORS[ERROR_CODE!]);

    } catch (error: any){
      if (error.response) {
        const ERROR_CODE = (error as AxiosError<DeleteBotResponse>).response?.data?.errors[0];
        dispatch({
          type: BOT_ACTIONS.DELETE_BOT_FAILED,
        });

        return callback && callback(true, String(error));
      }
    }
  }
}

export default deleteBot;