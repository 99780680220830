import { InputForm } from "../typings";

export const USER_COLUMNS = [
    {
        field: 'id',
        headerName: 'id',
        width: 250,
    },
    { 
        field: 'name',
        headerName: 'Nome completo',
        width: 350,
    },
    { 
        field: 'email',
        headerName: 'Email',
        width: 350,
    },
    { 
        field: 'phone',
        headerName: 'Telefone',
        width: 250,
    },
    { 
        field: 'created',
        headerName: 'Data de criação',
        width: 250,
    },
];

export const USER_FORM_INPUTS: InputForm[] = [
    {
        label: 'Nome',
        id: 'name',
        type: 'text',
        required: true,
        placeholder: 'Nome',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Email',
        id: 'email',
        type: 'text',
        required: true,
        placeholder: 'Email',
        grid: {
            sm: 12,
            xs: 12,
            md: 6,
        },
    },
    {
        label: 'Número do telefone',
        id: 'phone',
        type: 'text',
        mask: '(99) 9 9999-9999',
        required: false,
        placeholder: 'Número do telefone',
        grid: {
            sm: 12,
            xs: 12,
            md: 6,
        },
    },
    {
        label: 'CPF',
        id: 'cpf',
        type: 'text',
        required: false,
        placeholder: 'CPF',
        mask: '999.999.999-99',
        grid: {
            sm: 12,
            xs: 12,
            md: 6,
        },
    },
    {
        label: 'Data de nascimento',
        id: 'birthday',
        type: 'date',
        required: false,
        placeholder: 'Data de nascimento',
        grid: {
            sm: 12,
            xs: 12,
            md: 6,
        },
    },
    {
        label: 'Sexo',
        id: 'gender',
        type: 'select',
        required: false,
        placeholder: 'Sexo',
        grid: {
            sm: 12,
            xs: 12,
            md: 6,
        },
        options: [
            {
                label: 'Masculino',
                value: 'M',
            },
            {
                label: 'Feminino',
                value: 'F',
            },
        ],
    },
];