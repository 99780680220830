import { AnyAction } from 'redux';
import { Funnel } from '../../../typings';

import { FUNNELS_ACTIONS } from '../../actions/funnels';

export interface FunnelState {
    funnels: Funnel[], //Funnel,
    totalItems: number,
    loading: {
        index: boolean,
        destroy: boolean,
    },
    modal: {
        edit: boolean,
        create: boolean,
        import: boolean,
        delete: boolean,
    }
};

export const initialState: FunnelState = {
    funnels: [],
    totalItems: 0,
    loading: {
        index: false,
        destroy: false,
    },
    modal: {
        edit: false,
        create: false,
        import: false,
        delete: false,
    }
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // DESTROY
        case FUNNELS_ACTIONS.DESTROY_FUNNEL:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: true,
                },
            }

        case FUNNELS_ACTIONS.DESTROY_FUNNEL_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }
            
        case FUNNELS_ACTIONS.DESTROY_FUNNEL_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }

        // FETCH REPORTS
        case FUNNELS_ACTIONS.FETCH_FUNNELS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                },
            }

        case FUNNELS_ACTIONS.FETCH_FUNNELS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }

        case FUNNELS_ACTIONS.FETCH_FUNNELS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        // DEFAULT ACTIONS
        case FUNNELS_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        // MODAL HANDLER
        case FUNNELS_ACTIONS.TOGGLE_MODAL:
            return {
                ...state,
                modal: {
                    ...payload,
                }
            }

        case FUNNELS_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        
    }
}

export default reducer;