import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CopyIcon from '@mui/icons-material/CopyAll';

import Loading from '../../loading';
import NoData from '../../no-data';
import { AreYouSureModal } from '../..';
import { Funnel, FunnelFilters } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import { fetchFunnels, toggleModal } from '../../../store/actions/funnels';
import { PaginatorFactory } from '../../../factory';
import { FunnelState } from '../../../store/reducers/funnels';
import destroyFunnel from '../../../store/actions/funnels/destroyFunnel.action';
import { ImportLeadsModal } from '../../import-leads-modal';
import { FunnelForm } from '../../forms/funnel.form';
import { MoreOptionsList } from '../more-options';
import { FUNNEL_COLUMUNS } from '../../../builders/funnel.builders';
import { Label } from '../../label';
import { FunnelListFilters } from './funnel-list-filters';

export const FunnelList: React.FC = () => {
    const dispatch = useDispatch();
    const { funnels, loading, totalItems, modal} = useSelector<ReduxReducers, FunnelState>(s => s.funnels);

    const [pagination, setPagination] = useState<PaginatorFactory<Omit<Funnel, 'id'>>>({
        page: 0,
        perPage: 50,
      });

    const [selectedFunnel, setSelectedFunnel] = useState<Funnel>({} as Funnel);


    const onFilter = async (filters: FunnelFilters) => {
        dispatch(
            fetchFunnels(
                pagination?.page + 1,
                pagination?.perPage,
                filters,
            )
        );
    }

    useEffect(() => {
        dispatch(
            fetchFunnels(
                pagination?.page + 1,
                pagination?.perPage,
            )
        );
    }, [pagination]);

    return (
        <>
            <Card>
                <FunnelListFilters onFilter={onFilter} />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {FUNNEL_COLUMUNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                                <TableCell>
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <When condition={!loading.index}>
                            <TableBody>
                                {funnels?.map((funnel) => (
                                    <TableRow key={funnel?.id}>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {funnel?.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {funnel?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {funnel?.uuid}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Label
                                                variant={'ghost'}
                                                color={funnel?.active === 1 ? 'success' : 'error'}
                                            >
                                                <>
                                                    {
                                                        funnel?.active === 1 ? 'Sim' : 'Não'
                                                    }
                                                </>
                                            </Label>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(funnel?.created_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(funnel?.updated_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <MoreOptionsList
                                                options={[
                                                    {
                                                        icon: <EditIcon fontSize="small" />,
                                                        label: 'Editar',
                                                        onClick: () => {
                                                            setSelectedFunnel(funnel as Funnel);
                                                            dispatch(toggleModal('edit', true));
                                                        },
                                                    },
                                                    {
                                                        icon: <CopyIcon fontSize="small" />,
                                                        label: 'Clonar',
                                                        onClick: () => {
                                                            setSelectedFunnel(funnel as Funnel);
                                                            dispatch(toggleModal('create', true));
                                                        },
                                                    },
                                                    {
                                                        icon: <ImportExportIcon fontSize="small" />,
                                                        label: 'Importar',
                                                        onClick: () => {
                                                            setSelectedFunnel(funnel as Funnel);
                                                            dispatch(toggleModal('import', true));
                                                        },
                                                    },
                                                    {
                                                        icon: <DeleteIcon fontSize="small" />,
                                                        label: 'Excluir',
                                                        onClick: () => {
                                                            setSelectedFunnel(funnel as Funnel);
                                                            dispatch(toggleModal('delete', true));
                                                        },
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>

                    <When condition={loading?.index}>
                        <Loading />
                    </When>

                    <When condition={loading?.index !== true && funnels?.length < 1}>
                        <NoData 
                            buttonText='Adicionar'
                            onClick={() => dispatch(toggleModal('create', true))}
                            title={'Nenhum funil foi encontrado.'}
                            description={'Aparentemente, não há nenhum funil que contemple o filtro indicado.'}
                        />
                    </When>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                />
            </Card>

            <FunnelForm
                isFromEdit={modal?.edit}
                visible={modal?.edit || modal?.create}
                onHide={() => {
                    setSelectedFunnel({} as Funnel);
                    dispatch(toggleModal('edit', false));
                    dispatch(toggleModal('create', false));
                }}
                funnel={selectedFunnel}
            />

            <ImportLeadsModal
                visible={modal?.import}
                funnel={selectedFunnel}
                onHide={() => dispatch(toggleModal('import', false))}
            />

            <AreYouSureModal
                title="Tem certeza?"
                description="Tem certeza que deseja desconectar esse funil? Ele será excluído permanentemente."
                loading={loading?.destroy}
                visible={modal?.delete}
                onHide={() => dispatch(toggleModal('delete', false))}
                onConfirm={() => {
                    dispatch(
                        destroyFunnel(selectedFunnel?.id, (err, msg) => {
                            setSelectedFunnel({} as Funnel);
                            dispatch(toggleModal('delete', false));
                            toast(
                                msg,
                                {
                                    autoClose: 1500,
                                    type: err ? 'error' : 'success',
                                }
                            );
                        })
                    )
                }}
            />
        </>
    )
}