import { DefaultRes } from ".";

export interface NumberxBot {
    id: number;
    bot: number;
    phone: string;
    created_at: string;
}

export enum ActiveStatus {
    OFFLINE = -1,
    CONNECTING = 0,
    CONNECTED = 1,
    DELETED = -2,
}

export interface Bot {
    id: number;
    name: string;
    seconds_retry?: number;
    hourly_limit?: number;
    hourlyLimit: number;
    hourlyLimitSup: number;
    hourlyLimitInf: number;
    secondsRetry: number;
    number_x_bot?: NumberxBot[];
    active?: ActiveStatus;
    next_message_date?: string;
    args?: any;
    instances: any;
    instance: any;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export type CreateBotResponse = DefaultRes<Bot>;
export type UpdateBotResponse = DefaultRes<Bot>;
export type DeleteBotResponse = DefaultRes<string>;

export type IndexBotsResponse = DefaultRes<{
    data: Bot[],
    totalPages: number,
    perPage: number,
    page: Number,
    report: {
        totalBotsOnline: number,
        totalBotsConnecting: number,
        totalBotsOffline: number,
        totalUsedInstances: number,
    },
}>;

export type ConnectBotResponse = DefaultRes<{
    qrCode: string,
    message?: string,
}>;

export type VerifyBotStatusResponse = DefaultRes<{
    state: string,
    message?: string,
    phone: string,
    created_at: string,
    result?: any,
}>;

export type LogoutBotResponse = DefaultRes<string>;