import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
    visible: boolean,
    loading: boolean,
    title: string,
    description: string,
    onConfirm(): void,
    onHide(): void,
}

const AreYouSureModal: React.FC<Props> = ({
    visible,
    loading,
    title,
    description,
    onConfirm,
    onHide,
}) => {
    return (
        <Dialog
            open={visible}
            onClose={onHide}
            maxWidth={'sm'}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                {title}
            </DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <DialogContentText textAlign={'center'}>
                    {description}
                </DialogContentText>
                <img 
                    src="/static/illustrations/delete_illustration.svg" 
                    width={250}
                    height={200} 
                    style={{ alignSelf: 'center'}}
                />
            </DialogContent>
            <DialogActions sx={{ borderTop: 1, borderColor: 'divider', background: 'background.default' }}>
                <Button onClick={onHide} disabled={loading} color='inherit'>
                    Cancelar
                </Button>
                <LoadingButton onClick={onConfirm} autoFocus loading={loading} variant='contained' color='error'>
                    Confirmar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
};

export default AreYouSureModal;