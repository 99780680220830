import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';

import FormBuilder from '../../form-builder';
import { EventFilters, FunnelFilters } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FunnelState } from '../../../store/reducers/funnels';

interface Props {
    onFilter(filters: FunnelFilters): Promise<void>,
}

export const FunnelListFilters: React.FC<Props> = ({ onFilter }) => {
    const [open, setOpen] = useState(true);
    const { loading } = useSelector<ReduxReducers, FunnelState>(state => state.funnels);

    const initial =  {
        from: null,
        to: null,
        uuid: '',
        name: '',
        active: '',
    } as FunnelFilters;

    const formik = useFormik<FunnelFilters>({
        initialValues: { ...initial },
        onSubmit: async (filters) => {
           await onFilter(filters);
        }
    });

    const { 
        errors,
        touched,
        values,
        setFieldValue,
        getFieldProps,
    } = formik;

    return (
        <Box sx={{ p: 3 }}>
            <Accordion
                expanded={open}
                onChange={(e) => setOpen(o => !o)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <FilterListIcon sx={{ mr: 1.5, ml: -1 }} />
                    <Typography fontSize={18} fontWeight={500}>
                        Filtros disponíveis
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={12}>
                            <Grid container spacing={1}>
                                <FormBuilder
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    inputs={[
                                        {
                                            label: 'Nome',
                                            id: 'name',
                                            type: 'text',
                                            required: false,
                                            placeholder: 'Informe o nome do funil',
                                            grid: {
                                                xs: 4,
                                                sm: 4,
                                                md: 4,
                                            },
                                        },
                                        {
                                            label: 'UUID',
                                            id: 'uuid',
                                            type: 'text',
                                            required: false,
                                            placeholder: 'Informe o uuid do funil',
                                            grid: {
                                                xs: 4,
                                                sm: 4,
                                                md: 4,
                                            },
                                        },
                                        {
                                            label: 'Status',
                                            id: 'active',
                                            type: 'select',
                                            required: false,
                                            placeholder: 'Selecione o status do funil',
                                            grid: {
                                                xs: 4,
                                                sm: 4,
                                                md: 4,
                                            },
                                            options: [{
                                                label: 'Ativo',
                                                value: 1,
                                            }, {
                                                label: 'Inativo',
                                                value: 0,
                                            }],
                                        },
                                    ]}
                                    setFieldValue={setFieldValue}
                                    getFieldProps={getFieldProps}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item md={4} lg={4} xs={4} sm={4}>
                                        <DateTimePicker
                                            label="Data inicial:"
                                            ampm={false}
                                            value={values?.from}
                                            onChange={(newValue: any) => {
                                                formik.setFieldValue('from', newValue.toISOString());
                                            }}
                                            
                                            inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={4} lg={4} xs={4} sm={4}>
                                        <DateTimePicker
                                            label="Data final:"
                                            ampm={false}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                            value={values?.to}
                                            onChange={(newValue: any) => {
                                                formik.setFieldValue('to', newValue.toISOString());
                                            }}
                                            inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid display={'flex'} flexDirection={'row-reverse'} mt={2}>
                        <LoadingButton
                            variant='contained'
                            color={'primary'}
                            loading={loading?.index}
                            onClick={async () => {
                                await onFilter(values);
                            }}
                        >
                            Pesquisar
                        </LoadingButton>
                        <Button
                            disabled={loading?.index}
                            variant='text'
                            sx={{ mr: 1 }}
                            color={'primary'}
                            onClick={async () => {
                                formik.setValues({
                                    ...initial
                                });
                                await onFilter(initial as EventFilters);
                            }}
                        >
                            Limpar campos
                        </Button>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}