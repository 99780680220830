import { AnyAction } from 'redux';
import { Event } from '../../../typings';

import { EVENT_ACTIONS } from '../../actions/events';

export interface EventState {
    events: Event[], //Funnel,
    totalItems: number,
    loading: {
        index: boolean,
    },
};

export const initialState: EventState = {
    events: [],
    totalItems: 0,
    loading: {
        index: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // FETCH REPORTS
        case EVENT_ACTIONS.FETCH_EVENTS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                },
            }

        case EVENT_ACTIONS.FETCH_EVENTS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }

        case EVENT_ACTIONS.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        // DEFAULT ACTIONS
        case EVENT_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case EVENT_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        
    }
}

export default reducer;