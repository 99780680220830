import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, TextField, Box, InputLabel, Select } from '@mui/material';

import Loading from '../loading';
import { Event, EventFilters } from '../../typings';
import { ReduxReducers } from '../../store/reducers';
import { PaginatorFactory } from '../../factory';
import { FUNNEL_COLUMUNS } from '../../builders/funnel.builders';
import { EventState } from '../../store/reducers/events';
import { fetchEvents } from '../../store/actions/events';
import { EVENT_COLUMNS } from '../../builders/event.builders';
import { fetchFunnels } from '../../store/actions/funnels';
import NoData from '../no-data';
import FormBuilder from '../form-builder';
import { LoadingButton } from '@mui/lab';
import FormControl from '@mui/material/FormControl';
import { FunnelState } from '../../store/reducers/funnels';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { FUNNELS_ACTIONS } from '../../store/actions/funnels';

export const MasterLink: React.FC = () => {
    const dispatch = useDispatch();
    const { loading: loadingFunnel, funnels } = useSelector<ReduxReducers, FunnelState>(s => s.funnels);
    const { token } = useSelector<ReduxReducers, any>(s => s.auth);

    const [funnel, setFunnel] = React.useState([] as any);
    const [urlMasterLink, setUrlMasterLink] = React.useState('');
    const [loadingBtn, setLoadingBtn] = React.useState(false);

    const handleChangeMultiple = (event: any) => {
        const { options } = event.target;
        const value: any = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
                  value.push(options[i].value);
              }
          }
  
        setFunnel(value?.join(','));
    };

    const setMasterLink = async (action?: string) => {
        setLoadingBtn(true);
        try {
            const result = (await api.post('/Funnels/SetMasterLink', {
                masterLink: urlMasterLink,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    funnelIds: funnel,
                    action,
                },
            })).data;

            if (result.data === 'ok') {
                toast(
                    action ? 'MasterLink desativado com sucesso' : 'MasterLink setado com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );

                dispatch(
                    fetchFunnels(
                        1, 
                        100
                    )
                );

                setLoadingBtn(false);
                return true;
            }
        } catch (e: any) {
        }
        setLoadingBtn(false);
        toast(
            'Error ao tentar setar MasterLink ou desativar!',
            {
                autoClose: 1500,
                type: 'error',
            }
        );
    }

    return (
        <>
            <Box sx={{ p: 0.25 }}>
                <FormControl>
                    <InputLabel shrink htmlFor="select-multiple-native">
                            Funis:
                    </InputLabel>
                    <Select
                        style={{ marginBottom: 10 }}
                        multiple
                        native
                        onChange={handleChangeMultiple}
                        label="Native"
                        inputProps={{
                            id: 'select-multiple-native',
                        }}
                    >
                        {!loadingFunnel.index && funnels?.map((f) =>
                            <option key={f.uuid} value={f.id}>
                                ({f?.config?.masterLink ? 'Ativado' : 'Desativado'}) {f.name}
                            </option>
                        )}
                    </Select>
                    <TextField
                        style={{ marginBottom: 10 }}
                        value={urlMasterLink}
                        onChange={(p) => setUrlMasterLink(p.target.value)}
                        fullWidth
                        type='text'
                        label={'Link Mestre'}
                        placeholder={'Digite o Link Mestre'}
                        required={true}
                        error={false}
                    />
                    <LoadingButton
                        style={{ marginBottom: 10 }}
                        variant='contained'
                        color={'primary'}
                        loading={loadingBtn}
                        onClick={() => setMasterLink()}
                    >
                        Ativar Link Mestre
                    </LoadingButton>
                    <LoadingButton
                        variant='contained'
                        color={'warning'}
                        loading={loadingBtn}
                        onClick={() => setMasterLink('disable')}
                    >
                        Desativar Link Mestre
                    </LoadingButton>
                </FormControl>
            </Box>
        </>
    )
}