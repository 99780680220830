import React from 'react';
import {
    Stack,
    Button,
    Container,
} from '@mui/material';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';

import { 
    Page,
} from '../../../components';
import { UserList } from '../../../components/lists/user-list/user-list';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { toggleModal } from '../../../store/actions/users';


interface Props {}

const UsersScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();

    return (
        <>
            <Page title='PubZap - Usuários'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Usuários"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/admin/users'
                                },
                                {
                                    name: 'Administrador',
                                    href: '/admin/users'
                                },
                                { name: 'Usuários', href: '/admin/users' },
                            ]}
                            activeLast={false}
                        />
                        <Button
                            variant="contained"
                            onClick={() => dispatch(toggleModal('create', true))}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Adicionar
                        </Button>
                    </Stack>
                   <UserList />
                </Container>
            </Page>
        </>
    )
}

export default UsersScreen;