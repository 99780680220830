import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { USERS_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { DeleteUserResponse, } from '../../../typings';

const destroyUser = (
    id: number,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;
            const { users } = getState().users;

            dispatch({
                type: USERS_ACTIONS.DESTROY_USER,
            });

            const response = await api.delete<
                DeleteUserResponse, 
                AxiosResponse<DeleteUserResponse>
            >
            (`${ROUTES.USERS.INDEX}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.data && response.status === 200) {
                dispatch({
                    type: USERS_ACTIONS.DESTROY_USER_SUCCESS,
                    payload: {
                        users: users?.filter((u) => u?.id !== id),
                    },
                });
                
                return callback && callback(false, 'Usuário desativado com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: USERS_ACTIONS.DESTROY_USER_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DeleteUserResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: USERS_ACTIONS.DESTROY_USER_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: USERS_ACTIONS.DESTROY_USER_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default destroyUser;