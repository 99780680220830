import { AnyAction } from 'redux';
import { Report, ReportList } from '../../../typings';

import { REPORTS_ACTIONS } from '../../actions/reports';

export interface ReportState {
    reports: Report,
    allReports: ReportList[],
    total: number,
    loading: {
        index: boolean,
        destroy: boolean,
    },
};

export const initialState: ReportState = {
    reports: {} as Report,
    allReports: [] as ReportList[],
    total: 0,
    loading: {
        index: false,
        destroy: false
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // FETCH REPORTS
        case REPORTS_ACTIONS.FETCH_REPORTS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: true,
                    destroy: false
                },
            }

        case REPORTS_ACTIONS.FETCH_REPORTS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        case REPORTS_ACTIONS.FETCH_REPORTS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: false,
                    destroy: false
                },
            }
        // DEFAULT ACTIONS
        case REPORTS_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        // DELETE
        case REPORTS_ACTIONS.DELETE_REPORT:
          return {
              ...state,
              ...payload,
              loading: {
                ...state.loading,
                destroy: true,
            },
          };

        case REPORTS_ACTIONS.DELETE_REPORT_FAILED:
          return {
              ...state,
              // ...payload,
              loading: {
                ...state.loading,
                destroy: false,
            },
          };

        case REPORTS_ACTIONS.DELETE_REPORT_SUCCESS:
          return {
              ...state,
              ...payload,
              loading: {
                ...state.loading,
                destroy: false,
            },
          };

        case REPORTS_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        

    }
}

export default reducer;