import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { DeleteEventResponse } from '../../../typings';
import {REPORTS_ACTIONS} from './index'
import ERRORS from '../../../constants/errors.constants';
import { indexOf } from 'lodash';

const deleteEvent = (
  eventId: number,
  callback: (err: any, message?: string) => void
) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
    try {
      const { token } = getState().auth;
      const { allReports } = getState().reports;

      dispatch({
        type: REPORTS_ACTIONS.DELETE_REPORT,
      });
      
      const response = await api.delete<DeleteEventResponse, AxiosResponse<DeleteEventResponse>>(
        `${ROUTES.EVENTS.DELETE}/${eventId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log('response.status', response.status)

      if (response.status === 200) {
        const indexOfDeleted = allReports.findIndex(obj => obj.id === eventId)
        if(indexOfDeleted >= 0){
          allReports.splice(indexOfDeleted, 1)
        }

        dispatch({
          type: REPORTS_ACTIONS.DELETE_REPORT_SUCCESS,
          payload: {
            allReports,
          },
          // payload: {
          //   allReports: allReports?.map(b => {
          //     console.log(b.id + ' x ' + eventId)
          //     if (Number(b.id) !== Number(eventId)) {
          //       return { ...b };
          //     }

          //     return {
          //       ...b,
          //       type: 'DEL-REPORT',
          //     };
          //   }),
          // },
        });
        
        return callback && callback(false, 'Relatório deletado com sucesso!');
      }

      const ERROR_CODE = response.data?.errors[0];

      dispatch({
        type: REPORTS_ACTIONS.DELETE_REPORT_FAILED,
      });

      return callback && callback(true, ERRORS[ERROR_CODE!]);

    } catch (error: any) {
      if (error.response) {
        const ERROR_CODE = (error as AxiosError<DeleteEventResponse>).response?.data?.errors[0];
        dispatch({
          type: REPORTS_ACTIONS.DELETE_REPORT_FAILED,
        });

        return callback && callback(true, String(error));
      }
    }
  }
}

export default deleteEvent