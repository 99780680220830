import { AnyAction } from 'redux';
import { Instance } from '../../../typings';

import { INSTANCE_ACTIONS } from '../../actions/instances';

export interface InstanceState {
    instances: Instance[],
    totalItems: number,
    loading: {
        index: boolean,
    },
};

export const initialState: InstanceState = {
    instances: [],
    totalItems: 0,
    loading: {
        index: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // FETCH REPORTS
        case INSTANCE_ACTIONS.FETCH_INSTANCES:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                },
            }

        case INSTANCE_ACTIONS.FETCH_INSTANCES_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }

        case INSTANCE_ACTIONS.FETCH_INSTANCES_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        // DEFAULT ACTIONS
        case INSTANCE_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case INSTANCE_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        
    }
}

export default reducer;