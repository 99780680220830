import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
    login,
} from '../../../store/actions/auth/';
import { 
    ReduxReducers,
} from '../../../store/reducers';
import { AuthState } from '../../../store/reducers/auth';

export default function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading } = useSelector<ReduxReducers, AuthState>(s => s.auth);
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup
            .string()
            .email('E-mail incorreto')
            .required('Informe o seu e-mail'),
        password: Yup
            .string()
            .required('Informe a sua senha')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            dispatch(
                login(
                    values.email,
                    values.password,
                    (err?: boolean, message?: string) => {
                        formik.setSubmitting(false);
                        if (err) {
                            toast(
                                message, 
                                {
                                    autoClose: 1500,
                                    type: 'error',
                                }
                            )
                            return;
                        } 

                        toast(
                            message, 
                            {
                                autoClose: 1500,
                                type: 'success',
                            }
                        )
                        
                        navigate('/');
                    }
                )
            )
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Seu email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Sua senha"
                        {...getFieldProps('password')}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <FormControlLabel
                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                        label="Lembrar me"
                    />

                    <Link component={RouterLink} variant="subtitle2" to="#">
                        Esqueceu a senha?
                    </Link>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading?.login}
                >
                    Login
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}