import {
    Stack,
    Container,
} from '@mui/material';

import { 
    Page,
} from '../../../components';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { EventList } from '../../../components/lists/event-list/event-list';

interface Props {}

const EventsScreen: React.FC<Props> = () => {

    return (
        <>
            <Page title='PubZap - Eventos'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Eventos"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/admin/events'
                                },
                                {
                                    name: 'Administrador',
                                    href: '/admin/events'
                                },
                                { name: 'Eventos', href: '/admin/events' },
                            ]}
                            activeLast={false}
                        />
                    </Stack>
                    <EventList />
                </Container>
            </Page>
        </>
    )
}

export default EventsScreen;