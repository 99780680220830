export const REPORTS_ACTIONS = {
    // FETCH REPORTS
    FETCH_REPORTS: '[REPORTS] FETCH_REPORTS',
    FETCH_REPORTS_SUCCESS: '[REPORTS] FETCH_REPORTS_SUCCESS',
    FETCH_REPORTS_FAILED: '[REPORTS] FETCH_REPORTS_FAILED',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[REPORT] SET_VALUE',
    CLEAR_VALUES: '[REPORT] CLEAR_VALUES',

    // DELETE REPORT
    DELETE_REPORT: '[REPORT] DELETE_REPORT',
    DELETE_REPORT_SUCCESS: '[REPORT] DELETE_REPORT_SUCCESS',
    DELETE_REPORT_FAILED: '[REPORT] DELETE_REPORT_FAILED',
};

export const setValue = (payload: any) => ({
    type: REPORTS_ACTIONS.SET_VALUE,
    payload,
});

export const clearValues = () => ({ type: REPORTS_ACTIONS.CLEAR_VALUES });
export { default as fetchReports } from './fetchReport.action';
