import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Typography, CircularProgress } from '@mui/material';

interface Props {
    title: string,
    value: number | string,
    isLoading?: boolean,
};

const RootStyle = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 3),
}));

const Summary: React.FC<Props> = ({
    title,
    value,
    isLoading,
}) => {
    return (
        <RootStyle>
            <div>
                {!isLoading && <Typography variant="h3">{value}</Typography>}
                {isLoading && <CircularProgress color="inherit" />}
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {title}
                </Typography>
            </div>
        </RootStyle>
    )
}

export default Summary;